import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import background from "../img/new_cover.png";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

const Home = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header></Header>
      <div className="container mx-auto">
        <div className="w-full bg-header bg-center bg-no-repeat bg-cover">
          <div>
            <img src={background} className="mx-auto" />
          </div>
        </div>
        <div className="bg-white p-8">
          <div className="grid md:grid-cols-6 gap-2">
            <div className="col-span-3">
              <div className="container relative videoContainer">
                <iframe
                  // width="560"
                  // height="315"
                  src="https://www.youtube.com/embed/EHdAu3avhIc"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="absolute top-0 left-0 w-full h-full"
                ></iframe>
              </div>
            </div>
            <div className="col-span-3">
              <div className="font-bold text-lightBlue text-xl mb-10 font-mont">
                {t("homepage_title1")}
              </div>
              <div>{t("homepage_section1")}</div>
              <br />
              <div>{t("homepage_section2")}</div>
              <br />
              <div>{t("homepage_section3")}</div>
              <br />
            </div>
          </div>
          <div>{t("homepage_title2")}</div>
          <div className="text-left font-mont">
            <div className="text-lg mt-10 font-bold text-lightBlue">
              {t("homepage_title3")}
            </div>
            <div className="text-blue font-mont mt-7 font-bold">
              <a href="mailto:info@lead.org.mk">info@lead.org.mk</a>
            </div>
            <div><Link to="/privacyPolicy">{t("privacy_policy_title")}</Link></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
